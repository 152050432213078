// src/components/ContactForm.js
import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './ContactForm.css';

const ContactForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_4arornu', 'template_d70w57k', e.target, 'axCv70DPU-TeKf8Pe')
            .then((result) => {
                setSuccessMessage('Your message has been sent successfully!');
                setFormData({ name: '', email: '', message: '' });
            }, (error) => {
                setErrorMessage('There was an error sending your message. Please try again.');
            });
    };

    return (
        <form className="contact-form" onSubmit={handleSubmit}>
            <div className="form-group">
                <label>Name:</label>
                <input type="text" name="name" value={formData.name} onChange={handleChange} required />
            </div>
            <div className="form-group">
                <label>Email:</label>
                <input type="email" name="email" value={formData.email} onChange={handleChange} required />
            </div>
            <div className="form-group">
                <label>Message:</label>
                <textarea name="message" value={formData.message} onChange={handleChange} required />
            </div>
            <button type="submit" className="submit-button">Send</button>
            {successMessage && <p className="success-message">{successMessage}</p>}
            {errorMessage && <p className="error-message">{errorMessage}</p>}
        </form>
    );
};

export default ContactForm;
