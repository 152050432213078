import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Servicii from './components/Servicii';
import Products from './components/Products';
import './App.css';
import Portofolio from './components/Portofoliu';

const App = () => {
    return (
        <Router>
            <div className="app">
                <Navbar />
                <Routes>
                    <Route path="/" element={<Home />} />    
                    <Route path="/produse" element={<Products />} /> {/* Modifică ruta pentru produsele magazinului */}
                    <Route path="/servicii" element={<Servicii />} />
                    <Route path="/contact" element={<Contact />} />
                    <Route path="/portofoliu" element={<Portofolio />} />
                </Routes>
                <Footer />
            </div>
        </Router>
    );
};

export default App;
