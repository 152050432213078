import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from './assets/BiaDecor.png'; // Adjust the path based on your folder structure

const Navbar = () => {
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

    const handleMenuToggle = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const closeMobileMenu = () => {
        // Close the menu only if the screen width is less than 768px (mobile size)
        if (window.innerWidth < 768) {
            setIsMobileMenuOpen(false);
        }
    };
    
    return (
        <nav className="navbar">
            <div className="navbar-logo">
                <img src={logo} alt="BiaDecor Logo" className="logo-image" />
            </div>
    
            <div 
                className={`hamburger ${isMobileMenuOpen ? 'active' : ''}`} 
                onClick={handleMenuToggle}
            >
                <span></span>
                <span></span>
                <span></span>
            </div>
    
            <ul className={`navbar-links ${isMobileMenuOpen ? 'navbar-links-mobile active' : ''}`}>
                <li><Link to="/" onClick={closeMobileMenu}>Home</Link></li>
                <li><Link to="/servicii" onClick={closeMobileMenu}>Servicii</Link></li>
                <li><Link to="/produse" onClick={closeMobileMenu}>Produse</Link></li> 
                <li><Link to="/portofoliu" onClick={closeMobileMenu}>Portofoliu</Link></li>
                <li><Link to="/contact" onClick={closeMobileMenu}>Contact</Link></li>
            </ul>
        </nav>
    );
};

export default Navbar;
