import React, { useState } from 'react';
import './Portofoliu.css';

const Projects = () => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    const projectImages = [];

    for (let i = 1; i <= 60; i++) {
        projectImages.push(require(`./assets/proiecte/p${i}.jpg`));
    }
    
    const handleImageClick = (image, index) => {
        setSelectedImage(image);
        setCurrentImageIndex(index);
    };

    const handleCloseModal = () => {
        setSelectedImage(null);
    };

    const handlePrevImage = () => {
        setCurrentImageIndex((prevIndex) =>
            (prevIndex - 1 + projectImages.length) % projectImages.length
        );
    };

    const handleNextImage = () => {
        setCurrentImageIndex((prevIndex) =>
            (prevIndex + 1) % projectImages.length
        );
    };

    return (
        <div className="projects">
            <h1>Proiecte & Montaje</h1>
            <div className="gallery">
                {projectImages.map((image, index) => (
                    <img
                        key={index}
                        src={image}
                        alt={`Proiect ${index + 1}`}
                        className="project-image"
                        onClick={() => handleImageClick(image, index)}
                    />
                ))}
            </div>

            {selectedImage && (
                <div className="modal" onClick={handleCloseModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <span className="modal-close" onClick={handleCloseModal}>
                            &times;
                        </span>
                        <img
                            src={projectImages[currentImageIndex]}
                            alt="Expanded"
                            className="modal-image"
                        />
                        <span className="modal-nav modal-prev" onClick={handlePrevImage}>
                            &lt;
                        </span>
                        <span className="modal-nav modal-next" onClick={handleNextImage}>
                            &gt;
                        </span>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Projects;
