import React from 'react';
import './Servicii.css'; // Asigură-te că stilurile CSS sunt aplicate
import consultanta from './assets/consultanta.jpg';
import transport from './assets/transport.jpg';
import montaj from './assets/montaj1.jpeg';
import cusut from './assets/cusut.jpg';
const Servicii = () => {
    const services = [
        {
            name: 'Consultanță de amenajări interioare',
            description: 'Echipa noastră se deplasează la fața locului, face măsurători, schițe, calcule și propune soluții personalizate pentru realizarea proiectului. Consultantul oferă soluții adaptate preferințelor clientului.',
            image: consultanta,
        },
        {
            name: 'Transport gratuit',
            description: 'Transport gratuit în Oradea pentru obiecte voluminoase precum saltele sau alte produse. Noi ne asigurăm că ajung în siguranță la tine acasă.',
            image: transport,
        },

    ];
    const services_row_two = [
        {
            name: 'Servicii de montaj',
            description: 'Asigurăm montajul perdelelor, draperiilor și altor produse achiziționate de la noi, pentru ca totul să fie perfect pus la punct în casa ta.',
            image:montaj ,
        },
        {
            name: 'Servicii de cusut',
            description: 'Oferim servicii profesionale de cusut personalizat pentru perdele, draperii și alte textile. Totul este realizat conform specificațiilor tale.',
            image:cusut ,
        },
    ]
    return (
        <div className="services">
            <h1>Serviciile Noastre</h1>
            <div className="services-container">
                {services.map((service, index) => (
                    <div key={index} className="service-item">
                        <img src={service.image} alt={service.name} className="service-image" />
                        <h2 className="service-title">{service.name}</h2>
                        <p className="service-description">{service.description}</p>
                    </div>
                ))}
             {services_row_two.map((service, index) => (
                    <div key={index} className="service-item">
                        <img src={service.image} alt={service.name} className="service-image" />
                        <h2 className="service-title">{service.name}</h2>
                        <p className="service-description">{service.description}</p>
                    </div>
                ))}
            </div>
            
        </div>
    );
};

export default Servicii;
