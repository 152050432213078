import React, { useState } from 'react';
import './Home.css';
import elImg from './assets/2.png'; // Imaginea existentă
import image1 from './assets/carusel1.png'; // Prima imagine din carusel
import image2 from './assets/carusel2.png'; // A doua imagine din carusel
import image3 from './assets/carusel3.png'; // A treia imagine din carusel
import image4 from './assets/carusel4.png'; // A patra imagine din carusel

const Home = () => {
    // Carusel controlat prin state
    const [currentImage, setCurrentImage] = useState(0);
    const images = [image4, image3,image1, image2];

    const handlePrev = () => {
        setCurrentImage((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
    };

    const handleNext = () => {
        setCurrentImage((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    };

    return (
        <div className="home">
            <div className="card">
                {/* Caruselul */}
                <div className="carousel">
                    <div className="carousel-wrapper">
                        {/* Imaginea anterioară, mai mică și estompată */}
                        <img 
                            src={images[(currentImage === 0 ? images.length : currentImage) - 1]} 
                            alt="Previous" 
                            className="carousel-image small" 
                        />

                        {/* Imaginea curentă, proeminentă */}
                        <img 
                            src={images[currentImage]} 
                            alt="Current" 
                            className="carousel-image large" 
                        />

                        {/* Imaginea următoare, mai mică și estompată */}
                        <img 
                            src={images[(currentImage + 1) % images.length]} 
                            alt="Next" 
                            className="carousel-image small" 
                        />
                    </div>

                    {/* Butoane de navigare */}
                    <button className="carousel-btn prev" onClick={handlePrev}>❮</button>
                    <button className="carousel-btn next" onClick={handleNext}>❯</button>
                </div>

                <div className="column">
                    <h1>BIA DECOR</h1>
                    <p>
                        Bia Decor este un magazin specializat în comercializarea țesăturilor pentru perdele și draperii,
                        oferind o gamă diversificată de produse de calitate superioară. Fiecare produs din colecția noastră
                        este atent selectat pentru a aduce un plus de stil și eleganță în locuințele, hotelurile sau
                        restaurantele clienților noștri.
                    </p>

                    <p>
                        Sortimentul nostru include perdele și draperii într-o varietate de culori și modele, potrivite
                        pentru orice tip de interior, fie că este vorba de un design modern sau clasic. De asemenea,
                        oferim o gamă completă de articole textile pentru dormitor, cum ar fi lenjerii de pat fine,
                        cuverturi și perne decorative, dar și soluții textile pentru living, bucătărie, și alte
                        spații de locuit sau comerciale.
                    </p>

                    <h2>De ce să ne alegi?</h2>
                    <ul>
                        <li>Calitate garantată: Toate produsele noastre sunt selectate cu grijă pentru a asigura durabilitatea și estetica dorită.</li>
                        <li>Consultanță profesională: Echipa noastră de experți te va ajuta să alegi cele mai potrivite soluții pentru locuința ta.</li>
                        <li>Montaj profesionist: Asigurăm măsurători precise și montaj rapid pentru a-ți oferi o experiență fără stres.</li>
                        <li>Produse personalizate: Realizăm perdele și draperii pe comandă, conform preferințelor tale.</li>
                    </ul>

                    <img src={elImg} alt="Asociația de tineret ELF" className="home-image" />
                </div>
            </div>
        </div>
    );
};

export default Home;
