import React from 'react';
import './Footer.css';

const Footer = () => {
    return (
        <footer className="footer">
            <p>&copy; 2024 Bia Decor. All rights reserved.</p>
            <div className="footer-social">
                <a href="https://www.facebook.com/profile.php?id=100011235074517">Facebook</a>
            </div>
        </footer>
    );
};

export default Footer;
