import React from 'react';
import ContactForm from './ContactForm';
import './Contact.css';
import logo from './assets/BiaDecor.png';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import markerIconUrl from './assets/BiaDecor.png'; 

const Contact = () => {
    const oradeaPosition = [47.03187064665047, 21.94803905320297];
    const beiusPosition = [46.666891292791504, 22.34900814631587];

    const customIcon = new L.Icon({
        iconUrl: markerIconUrl,
        iconSize: [62, 62],
        iconAnchor: [16, 32],
        popupAnchor: [0, -32]
    });

    return (
        <div className="contact-container">
            <div className="contact-content">
                <div className="contact-left">
                    <img src={logo} alt="Asociația de tineret ELF" className="home-image" />
                    <h1>Contact</h1>
                    <p>Dacă ai întrebări sau dorești să ne contactezi, completează formularul de mai jos:</p>
                    <div className="contact-info">
                        <p><strong>E-mail:</strong> alebialux@hotmail.com</p>
                        <p><strong>Tel:</strong> 0725649573</p>
                        <ContactForm />
                    </div>
                </div>

                <div className="contact-right">
                    <h2>Ne găsești pe hartă</h2>
                    <div className="map-container">
                        <h3>Oradea - Str. Chiheiului, nr. 11</h3>
                        <MapContainer center={oradeaPosition} zoom={13} style={{ height: '400px', width: '100%' }}>
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            />
                            <Marker position={oradeaPosition} icon={customIcon}>
                                <Popup>Oradea, Str. Chiheiului, nr. 11</Popup>
                            </Marker>
                        </MapContainer>
                    </div>
                    <div className="map-container">
                        <h3>Beiuș - Str. Aleea Moților, nr. 16</h3>
                        <MapContainer center={beiusPosition} zoom={13} style={{ height: '400px', width: '100%' }}>
                            <TileLayer
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                            />
                            <Marker position={beiusPosition} icon={customIcon}>
                                <Popup>Beiuș, Str. Aleea Moților, nr. 16</Popup>
                            </Marker>
                        </MapContainer>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contact;
