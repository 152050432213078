import React, { useState } from 'react';
import './Products.css';

// Imagini pentru galerie
import perdeleDraperii from './assets/perdeleDraperii.jpg';
import Saltele from './assets/saltele.jpg';
import Accesorii from './assets/accesori.jpeg';
import Galerii from './assets/galerii.jpg';
import Lenjerii from './assets/lenjerii.jpg';
import l1 from './assets//coletii/Lilium/l1.jpg';
import l2 from './assets//coletii/Lilium/l2.jpg';
import l3 from './assets//coletii/Lilium/l3.jpg';
import l4 from './assets//coletii/Lilium/l4.jpg';
import l5 from './assets//coletii/Lilium/l5.jpg';
import l6 from './assets//coletii/Lilium/l6.jpg';
import l7 from './assets//coletii/Lilium/l7.jpg';
import l8 from './assets//coletii/Lilium/l8.jpg';
import l9 from './assets//coletii/Lilium/l9.jpg';
import l10 from './assets//coletii/Lilium/l10.jpg';
import l11 from './assets//coletii/Lilium/l11.jpg';

import W1 from './assets/coletii/Winbrella/W1.jpg';
import W2 from './assets/coletii/Winbrella/W2.jpg';
import W3 from './assets/coletii/Winbrella/W3.jpg';
import W4 from './assets/coletii/Winbrella/W4.jpg';
import W5 from './assets/coletii/Winbrella/W5.jpg';
import W6 from './assets/coletii/Winbrella/W6.jpg';
import W7 from './assets/coletii/Winbrella/W7.jpg';
import W8 from './assets/coletii/Winbrella/W8.jpg';
import W9 from './assets/coletii/Winbrella/W9.jpg';
import W10 from './assets/coletii/Winbrella/W10.jpg';
import W11 from './assets/coletii/Winbrella/W11.jpg';

const Products = () => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [currentCollection, setCurrentCollection] = useState(null);
    const [expandedItems, setExpandedItems] = useState({});

    // Split the products into two rows
  
const firstRowProducts = [
    {
        category: 'Perdele & Draperii',
        image: perdeleDraperii,
        items: [
            { name: 'Perdele elegante', details: 'Perdele din materiale rafinate, cu un design sofisticat și o țesătură fină, care îmbină estetică și funcționalitate pentru un decor luxos. Ideale pentru controlul luminii și crearea unei atmosfere relaxante.' },
            { name: 'Draperii moderne', details: 'Draperii cu design contemporan, realizate din materiale premium cu proprietăți termoizolante și fonice. Acestea adaugă stil și protecție termică, fiind perfecte pentru camere luminoase și moderne.' },
            { name: 'Perdele cu modele', details: 'Perdele cu imprimeuri artistice, create prin tehnologie digitală avansată. Acestea oferă o explozie de culoare și un plus de personalitate decorului, fiind disponibile într-o varietate de modele și stiluri.' }
        ]
    },
    {
        category: 'Saltele',
        image: Saltele,
        items: [
            { name: 'Saltele ortopedice', details: 'Saltele ortopedice cu susținere optimă, menținând alinierea naturală a coloanei vertebrale. Concepute pentru a preveni durerile de spate și a asigura un somn profund și odihnitor.' },
            { name: 'Saltele superortopedice', details: 'Saltele superortopedice cu tehnologie avansată de stratificare a spumei, oferind confort maxim și susținere precisă pentru fiecare zonă a corpului. Perfecte pentru cei care preferă un pat ferm.' },
            { name: 'Saltele pocket spring', details: 'Saltele cu arcuri individuale în buzunare separate (pocket spring), concepute pentru a reduce presiunea și a distribui greutatea uniform pe toată suprafața saltelei, asigurând un confort de lungă durată.' },
            { name: 'Saltele memory foam', details: 'Saltele cu spumă cu memorie care se adaptează perfect la forma corpului, oferind un nivel unic de confort și susținere personalizată. Ideale pentru un somn liniștit și relaxant.' },
            { name: 'Saltele latex', details: 'Saltele din latex natural, hipoalergenice și respirabile, care oferă un mediu sănătos și confortabil pentru somn. Latexul natural asigură o ventilație optimă și o reglare excelentă a temperaturii corpului.' }
        ]
    },
    {
        category: 'Lenjerii',
        image: Lenjerii,
        items: [
            { name: 'Lenjerii bumbac 100%', details: 'Lenjerii din bumbac 100%, recunoscute pentru capacitatea lor de a respira și de a oferi un confort natural pe tot parcursul anului. Textura moale și durabilă le face alegerea ideală pentru orice dormitor.' },
            { name: 'Lenjerii bumbac satinat', details: 'Lenjerii din bumbac satinat, cu un aspect elegant și o textură netedă, oferind o senzație luxoasă și un plus de confort în fiecare noapte. Bumbacul satinat combină rezistența cu delicatețea pentru un somn desăvârșit.' },
            { name: 'Materiale pentru lenjerii', details: 'O varietate de materiale de înaltă calitate pentru lenjerii, disponibile în diferite țesături și culori, concepute pentru a răspunde oricăror preferințe. Fiecare material este selectat pentru a oferi durabilitate și confort.' }
        ]
    }
];

const secondRowProducts = [
    {
        category: 'Sisteme de prindere',
        image: Galerii,
        items: [
            { name: 'Galerii de lemn', details: 'Galerii din lemn masiv, atent finisate, oferind o soluție durabilă și elegantă pentru prinderea perdelelor. Disponibile în mai multe nuanțe și stiluri, aceste galerii adaugă un plus de căldură și rafinament.' },
            { name: 'Galerii de metal', details: 'Galerii metalice cu finisaje de înaltă calitate, rezistente la coroziune și proiectate pentru un stil modern și minimalist. Aceste galerii oferă un suport solid și estetic pentru orice tip de perdele.' },
            { name: 'Sine de perdele', details: 'Sisteme de prindere cu sine discrete și elegante, care se integrează perfect în decorul minimalist. Sunt ideale pentru perdelele ușoare și moderne, asigurând o funcționare lină și fără efort.' }
        ]
    },
    {
        category: 'Accesorii',
        image: Accesorii,
        items: [
            { name: 'Magneti', details: 'Magnete decorative și funcționale, disponibile într-o gamă variată de forme și culori, care adaugă un detaliu stilistic perdelelor tale și permit prinderea lor în poziția dorită.' },
            { name: 'Suporti de prindere', details: 'Suporturi de prindere robuste, compatibile cu o varietate de galerii și sine, concepute pentru a oferi stabilitate și un aspect estetic rafinat în orice tip de amenajare interioară.' },
            { name: 'Ciucuri', details: 'Ciucuri eleganți, fabricați din materiale de calitate superioară, care adaugă un plus de rafinament și stil perdelelor. Disponibili într-o varietate de culori pentru a se potrivi perfect cu decorul casei.' }
        ]
    }
];
    

    const collections = [
        {
            name: 'Lilium',
            images: [l1 , l2 , l3 , l4 ,l5 , l6 , l7 , l8 , l9,l10,l11]
        },
        {
            name: 'Winbrella',
            images: [W1, W2, W3, W4, W5, W6, W7, W8, W9, W10, W11]
        }
    ];

    const handleImageClick = (image, index, collection) => {
        setSelectedImage(image);
        setCurrentImageIndex(index);
        setCurrentCollection(collection);
    };

    const handleCloseModal = () => {
        setSelectedImage(null);
        setCurrentCollection(null);
    };

    const handlePrevImage = () => {
        if (currentCollection) {
            const images = currentCollection.images;
            setCurrentImageIndex((prevIndex) => 
                (prevIndex - 1 + images.length) % images.length
            );
        }
    };

    const handleNextImage = () => {
        if (currentCollection) {
            const images = currentCollection.images;
            setCurrentImageIndex((prevIndex) => 
                (prevIndex + 1) % images.length
            );
        }
    };

    const getCurrentCollectionImages = () => {
        return currentCollection ? currentCollection.images : [];
    };

    const isItemExpanded = (categoryIndex, itemIndex) => {
        return expandedItems[categoryIndex]?.[itemIndex];
    };

    const toggleItemDetails = (categoryIndex, itemIndex) => {
        setExpandedItems((prev) => ({
            ...prev,
            [categoryIndex]: {
                ...prev[categoryIndex],
                [itemIndex]: !prev[categoryIndex]?.[itemIndex]
            }
        }));
    };
    

    return (
        <div className="products">
            <h1>Produse</h1>

            <div className="card-container">
                <h2>Categorii de Produse</h2>
                
                {/* Render first row of products */}
                <div className="cards">
                    {firstRowProducts.map((product, categoryIndex) => (
                        <div key={categoryIndex} className="product-card">
                            <img src={product.image} alt={product.category} />
                            <h3>{product.category}</h3>
                            <ul>
                                {product.items.map((item, itemIndex) => (
                                    <li 
                                    key={itemIndex}
                                    className={isItemExpanded(categoryIndex, itemIndex) ? 'expanded' : ''}
                                    onClick={() => toggleItemDetails(categoryIndex, itemIndex)}
                                >
                                    {item.name}
                                    {isItemExpanded(categoryIndex, itemIndex) && (
                                        <div className="item-details">
                                            {item.details}
                                        </div>
                                    )}
                                </li>
                                
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>

                {/* Render second row of products */}
                <div className="cards">
                    {secondRowProducts.map((product, categoryIndex) => (
                        <div key={categoryIndex} className="product-card">
                            <img src={product.image} alt={product.category} />
                            <h3>{product.category}</h3>
                            <ul>
                                {product.items.map((item, itemIndex) => (
                                    <li key={itemIndex}>
                                        <div onClick={() => toggleItemDetails(categoryIndex, itemIndex)}>
                                            {item.name}
                                        </div>
                                        {isItemExpanded(categoryIndex, itemIndex) && (
                                            <div className="item-details">
                                                {item.details}
                                            </div>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>

            <div className="card-container">
                <h2>Colecții</h2>
                <div className="cards">
                    {collections.map((collection, index) => (
                        <div key={index} className="collection-card">
                            <h3>{collection.name}</h3>
                            <div className="collection-images">
                                {/* Afișează doar prima imagine din colecție */}
                                <img 
                                    src={collection.images[0]} 
                                    alt={`Colecție ${collection.name}`} 
                                    className="collection-image" 
                                    onClick={() => handleImageClick(collection.images[0], 0, collection)} 
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {selectedImage && currentCollection && (
                <div className="modal" onClick={handleCloseModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <span className="modal-close" onClick={handleCloseModal}>&times;</span>
                        <img 
                            src={getCurrentCollectionImages()[currentImageIndex]} 
                            alt="Expanded" 
                            className="modal-image" 
                        />
                        <span className="modal-nav modal-prev" onClick={handlePrevImage}>&lt;</span>
                        <span className="modal-nav modal-next" onClick={handleNextImage}>&gt;</span>
                    </div>
                </div>
            )}
            
        </div>
    );
};

export default Products;
